.change-password {
  .password-field {
    &__info {
      width: 100%;
      background-color: $color-white;
      z-index: 99;
      margin-top: 5px;
      &:before {
        @include breakpoint($medium-up) {
          content: '';
          position: absolute;
          top: 47%;
          #{$ldirection}: -11px;
          border: 5px solid transparent;
          border-#{$rdirection}-color: $color-gray;
          transform: rotate(360deg);
        }
      }
      @include breakpoint($medium-up) {
        position: absolute;
        #{$ldirection}: 87%;
        top: 20%;
        transform: translateY(-50%);
        width: 210px;
        border: 1px solid $color-gray;
      }
      @include breakpoint($medium-portrait-only) {
        #{$ldirection}: 35%;
        width: 25%;
        top: 37%;
        &:before {
          top: 100%;
          #{$ldirection}: 50%;
          -ms-transform: rotate(-90deg);
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
      @include breakpoint($ipad-pro-portrait-only) {
        width: 23%;
        top: 22%;
      }
      &-gnav {
        position: relative;
        #{$ldirection}: 0;
        top: 30px;
        width: 100%;
        border: 0;
      }
      &-reset {
        @include breakpoint($medium-up) {
          width: 55%;
        }
        @include breakpoint($medium-portrait-only) {
          width: 22%;
          #{$ldirection}: 80%;
        }
        @include breakpoint($ipad-pro-portrait-only) {
          width: 45%;
        }
        bottom: auto;
        top: 40%;
        width: 100%;
        #{$ldirection}: 401px;
        &:before {
          #{$ldirection}: 0;
          top: 45%;
          transform: translate(-110%, -50%);
        }
      }
      &-checkout {
        @include breakpoint($medium-up) {
          width: 29%;
          #{$ldirection}: 42%;
          padding: 1px;
        }
        @include breakpoint($medium-portrait-only) {
          width: 43%;
          #{$rdirection}: 79%;
        }
        @include breakpoint($ipad-pro-portrait-only) {
          width: 35%;
          #{$rdirection}: 73%;
        }
        width: 100%;
        &:before {
          @include breakpoint($medium-up) {
            #{$ldirection}: -5%;
            -ms-transform: rotate(0);
            -webkit-transform: rotate(0);
            transform: rotate(0);
          }
        }
      }
    }
    &__rules {
      font-size: 9px;
      @include breakpoint($medium-up) {
        font-size: 12px;
      }
    }
  }
  .profile__change-password-overlay {
    .profile__reset-password {
      .profile-info {
        &__item {
          input {
            width: 80%;
          }
        }
        &__item {
          .form-cancel {
            float: #{$ldirection};
            margin-#{$rdirection}: 3%;
            width: 38%;
          }
        }
      }
    }
  }
}
