.postal_code_container {
  input {
    padding: 5px 35px;
    width: 100%;
  }
  label {
    margin-#{$ldirection}: 20px;
  }
  .icon {
    height: 15px;
    width: 15px;
    &--search {
      float: $ldirection;
      position: relative;
      #{$ldirection}: 0.5rem;
      top: -2rem;
    }
    &--caret--down {
      position: relative;
      top: -2rem;
      float: $rdirection;
      #{$rdirection}: 1rem;
    }
  }
}
