@import '../../../cremedelamer2_base/scss/_theme-bootstrap.scss';

// DB Heavent
@font-face {
  font-family: 'DB Heavent';
  src: url('#{$netstorage-font-path}DBHeavent/DBHeavent-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// DB Heavent - Light
@font-face {
  font-family: 'DB Heavent Light';
  src: url('#{$netstorage-font-path}DBHeavent/DBHeavent-Light.woff2') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

$font--neue-haas-unica-pro: 'Neue Haas Unica Pro', 'DB Heavent', sans-serif;
$font--lamer-headline: 'La Mer Headline', 'DB Heavent Light', serif;
$font--lamer-text: 'La Mer Text', 'DB Heavent Light', serif;
$font--dbheavent: 'DB Heavent';
$font--dbheavent-light: 'DB Heavent Light';
$font--sans-serif: sans-serif;

.favorites-page {
  .text--1 {
    font-size: 20px;
  }
  .favorite__list {
    &-actions__header {
      font-size: 20px;
    }
  }
}

.order-details-page,
.orders-page {
  .order_table {
    th {
      font-size: 20px;
    }
  }
  .order-details {
    &__subheader {
      font-size: 30px;
    }
  }
}

.checkout {
  &-progress-bar {
    &__list-item {
      font-size: 18px;
    }
  }
  &-panel {
    &__content {
      font-size: 20px;
    }
  }
  &__panel {
    &-title {
      font-size: 28px;
    }
  }
}

.sign-in-page,
.signin-panel {
  .social-login {
    &__email-opt-in,
    &__terms,
    &__divider {
      font-size: 20px;
      font-family: $font--dbheavent;
    }
  }
}

.signin-panel {
  .checkout {
    &-panel {
      &__heading {
        font-size: 28px;
      }
      &__content {
        font-size: 14px;
      }
    }
  }
  .new-account {
    &__item {
      .label-content,
      label,
      .show-password,
      .address-information,
      .selectBox-label {
        font-size: 18px;
      }
    }
  }
  .ship_type_container {
    font-size: 18px;
  }
}

#settings {
  .account-setting {
    &__section {
      &.account {
        &-profile,
        &-address {
          .section-header {
            &__header {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

.form-item {
  &.forgot {
    font-size: 20px;
  }
}

.samples-content {
  .button,
  .link--secondary {
    font-size: 16px;
  }
  .product-size {
    font-family: $font--dbheavent;
  }
  .button {
    font-size: 20px;
    line-height: 0.5;
  }
}

.ff {
  &-question {
    &__headline {
      font-family: $font--dbheavent;
      &--secondary {
        font-family: $font--dbheavent !important;
        line-height: 1;
        span {
          font-size: 22px !important;
        }
      }
    }
  }
  &-results {
    &__subheadline {
      font-size: 30px;
    }
    .headline--section {
      &.ff-results {
        &__quiz_results_text {
          font-family: $font--dbheavent;
          font-size: 22px;
        }
      }
    }
  }
  &-quiz {
    &__footer-container {
      font-family: $font--dbheavent;
      font-size: 22px;
    }
  }
}

.change_link {
  font-size: 20px;
}

.button {
  font-size: 16px;
}

.site-footer-pc {
  .menu {
    &__item {
      a {
        font-size: 20px;
      }
    }
  }
  &__column--1 {
    .menu {
      &__item {
        &.first {
          a {
            font-size: 15px;
          }
        }
      }
    }
  }
  .site-email-signup {
    &__fields {
      &--email {
        input[type='text'] {
          font-size: 20px;
          &::-webkit-input-placeholder {
            font-family: $font--dbheavent;
            font-size: 20px;
          }
        }
      }
    }
  }
  .social-media-links {
    margin-top: 100px;
  }
}

.site-header {
  &__utility-item {
    .__link,
    &__link {
      font-size: 18px;
    }
  }
  &__nav {
    .site-header {
      &__account-menu-select {
        &.selectBox {
          font-size: 18px;
          margin: 5px 0;
          height: 1.1em;
          span {
            &.selectBox-arrow {
              top: 7px;
              right: 0;
            }
          }
        }
      }
    }
  }
  .login {
    &__header,
    &__forgot-password {
      font-size: 18px;
    }
    &__container {
      input[type='submit'] {
        font-family: $font--neue-haas-unica-pro;
        font-size: 16px;
        line-height: 0.7;
      }
      input[type='text'],
      input[type='password'] {
        font-size: 14px;
        &::-webkit-input-placeholder {
          font-family: $font--dbheavent;
          font-size: 14px;
        }
      }
    }
    &__registration-alt {
      .login {
        &__register,
        &__no-account {
          font-size: 18px;
          line-height: 0.5;
        }
      }
    }
  }
  .social-login {
    label,
    &__terms {
      font-size: 18px;
    }
  }
}

div.site-content {
  .landing-page {
    .offer-banner-item {
      &__body {
        p {
          font-size: 23px;
        }
      }
    }
  }
  .mpp {
    &-sort {
      .selectBox-label {
        font-size: 18px;
      }
    }
    &-filter-set {
      &__title,
      &__count {
        font-size: 18px;
        &:before {
          top: 20px;
        }
        span {
          font-family: $font--dbheavent;
        }
      }
    }
  }
  span.mpp-filter-set__button {
    font-family: $font--sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    &:before {
      top: 13px;
    }
  }
  div.product-brief {
    &__cta {
      .product-add-to-favorites {
        a {
          font-size: 18px;
        }
      }
    }
    &__price {
      .product-price {
        font-family: $font--dbheavent;
        font-size: 22px;
      }
    }
  }
  .product-quickshop {
    &__container {
      .product-quickshop {
        &__offer {
          font-size: 18px;
        }
      }
      .product-section-title {
        font-size: 20px;
      }
    }
    &__cta {
      .button {
        font-size: 18px;
        line-height: 0.5;
      }
    }
  }
  .product-add-to-favorites {
    .link {
      font-size: 18px;
    }
  }
  .filter-no-matches {
    &__cta {
      .link--secondary {
        font-size: 16px;
      }
    }
  }
  .spp {
    &__container {
      .product-full {
        &__accordion {
          &__title,
          &__panel {
            font-size: 20px;
            font-family: $font--dbheavent;
          }
          &__panel {
            line-height: 1;
          }
        }
        &__offer {
          &__content {
            font-size: 20px;
          }
        }
        &__desc {
          font-family: $font--dbheavent;
          line-height: 1;
        }
      }
      .product-add-to-cart {
        .button {
          font-size: 20px;
          height: 40px;
          line-height: 0.5;
        }
      }
      .content-block {
        &-collapsible {
          &__content-body {
            .heading {
              font-family: $font--dbheavent;
              font-size: 23px;
            }
          }
          &__content-item {
            .video-steps {
              &__step-description {
                span {
                  font-family: $font--dbheavent;
                  font-size: 22px !important;
                  line-height: 1;
                }
              }
            }
          }
        }
      }
      .product-full-v1 {
        .product-add-to-cart {
          .button {
            height: 65px;
            line-height: 1;
          }
        }
        .sticky-add-to-bag {
          .product-add-to-cart {
            .button {
              line-height: 1.5;
            }
          }
        }
        .product-full__accordion {
          .product-full__accordion__title {
            line-height: 2.5;
          }
        }
        .product-full__accordion__title {
          line-height: 2.5;
        }
      }
    }
  }
  &.sign-in-page {
    .button {
      font-size: 20px;
    }
    .new-account,
    .return-user {
      .section-head {
        &__header {
          font-size: 34px;
        }
      }
      &__fieldset {
        .no-account,
        .sign-up-btn,
        .sign-in-btn {
          &.form-item {
            font-size: 20px;
          }
        }
        .show-password {
          .label {
            font-size: 20px;
          }
        }
        .address_information {
          font-size: 20px;
        }
      }
    }
  }
  .basic-responsive {
    font-size: 20px;
    font-family: $font--dbheavent;
  }
  .text--1 {
    font-family: $font--dbheavent;
  }
  .content-block {
    &-tout__text-body,
    &-formatter__body {
      .heading {
        font-family: $font--dbheavent;
        font-size: 23px;
        line-height: 1;
      }
    }
  }
  .content-block-collapsible {
    &__content-body {
      .heading {
        font-family: $font--dbheavent;
        font-size: 23px;
        line-height: 1;
      }
    }
  }
  .content-block-large {
    &__content-text {
      &__content {
        .heading {
          font-family: $font--dbheavent !important;
          font-size: 23px !important;
          line-height: 1 !important;
          span {
            font-size: 18px !important;
          }
        }
      }
    }
  }
  .product-subline {
    font-family: $font--sans-serif;
    font-size: 15px;
    line-height: 15px;
    font-weight: 400;
  }
  .olapic-block {
    &__body {
      font-size: 22px;
      font-family: $font--dbheavent;
    }
  }
}

.viewcart {
  .checkout {
    &__heading {
      font-size: 32px;
      @include breakpoint($landscape-up) {
        font-size: 52px;
      }
      &__count {
        &__value {
          font-family: $font--dbheavent-light;
        }
      }
    }
  }
  .checkout-buttons-content {
    .continue-checkout {
      font-size: 16px;
    }
  }
  .cart-items {
    &__header {
      font-size: 20px;
    }
  }
  .remove_link,
  .link,
  .continue-checkout {
    font-size: 18px;
  }
  .addtobag {
    input {
      font-family: $font--dbheavent;
      font-size: 20px;
      line-height: 0.5;
    }
  }
}

.checkout-panel {
  &--links {
    .checkout-panel {
      &__heading {
        font-size: 30px;
      }
    }
  }
  .offer-code-content {
    &__form,
    &__one-offer {
      font-size: 15px;
    }
    &__btn {
      input {
        font-family: $font--dbheavent;
        font-size: 20px;
      }
    }
  }
}

.cart-item {
  &__product-name {
    font-size: 20px;
    font-family: $font--dbheavent;
  }
  &__total {
    font-family: $font--dbheavent;
  }
  &__price {
    font-family: $font--dbheavent;
    font-size: 20px;
  }
}

.address_controls {
  .selectbox {
    font-size: 20px;
    font-family: $font--dbheavent;
  }
}

.address-overlay_submit {
  font-family: $font--dbheavent;
  font-size: 16px;
}

.value,
.formatted_price,
.price,
.total {
  font-family: $font--dbheavent;
  font-size: 20px;
}

.product-sku-price {
  &__value {
    font-family: $font--dbheavent;
    font-size: 20px;
  }
}

.account-section {
  &__nav {
    a {
      font-size: 18px;
    }
  }
  .section-header,
  .overview-order-info,
  .default_address_title {
    font-size: 20px;
  }
  .profile-info {
    .label,
    p {
      font-size: 20px;
    }
  }
  .section-footer {
    &__link {
      font-size: 20px;
    }
  }
  .account-settings {
    &__container {
      .section-header {
        .section-head {
          &__link {
            font-size: 16px;
          }
        }
      }
      .section-content {
        .address-item {
          &__address {
            .address-item {
              &__controls {
                a {
                  font-size: 18px;
                }
              }
            }
          }
        }
        .account-info {
          font-size: 20px;
          .account-profile {
            &__newsletter {
              .label {
                width: 200px;
              }
            }
          }
        }
        .account-profile {
          &__connected-accounts {
            font-size: 20px;
          }
        }
      }
    }
  }
  .account-profile {
    &__last-order {
      &--date,
      &--number {
        font-family: $font--dbheavent;
      }
    }
  }
  .orders-list {
    &__table {
      &--order-number,
      &--order-total,
      &--order-date {
        font-family: $font--dbheavent;
      }
    }
  }
  .link--secondary {
    font-size: 14px;
  }
  .overview-heading {
    font-size: 36px;
  }
  &.account-page {
    .account-wishlist,
    .account-buy-it-again {
      .section-header {
        &__header {
          font-size: 30px;
        }
      }
    }
  }
}

.order-summary {
  &__content,
  &-content {
    font-family: $font--dbheavent;
    font-size: 20px;
  }
  &__shipping-method {
    text-align: left;
    font-size: 20px;
  }
}

.number-font,
.profile-info {
  font-family: $font--dbheavent;
}

.sku-list {
  &__item {
    font-family: $font--dbheavent;
    .button {
      font-size: 16px;
    }
  }
}

.address-item {
  &__info {
    font-family: $font--dbheavent;
    font-size: 18px;
  }
}

.customer-service {
  &__menu {
    a {
      &.menu__link--lvl-1,
      &.menu__link--lvl-2 {
        font-size: 20px;
        line-height: 1;
      }
    }
  }
  &_columns_container {
    .customer-service {
      &__subtitle {
        font-family: $font--dbheavent;
        font-size: 20px;
        line-height: 1;
      }
      &__collapsible-block {
        .collapsible-block {
          &__header {
            font-family: $font--dbheavent;
            font-size: 20px;
            line-height: 1;
          }
          &__content {
            &-item {
              font-family: $font--dbheavent;
              font-size: 20px;
              line-height: 1;
            }
          }
        }
      }
    }
  }
  .cs-contact-info {
    &__description {
      font-size: 20px;
      font-family: $font--dbheavent;
    }
  }
}

.order-details {
  &__item {
    font-family: $font--dbheavent;
    font-size: 20px;
    .gift {
      &_info,
      &_wrap {
        font-size: 20px;
      }
    }
  }
}

.shipping {
  .address-container {
    font-family: $font--dbheavent;
  }
  .checkout {
    &__panel {
      &-title {
        font-family: $font--dbheavent-light;
      }
    }
  }
  .bankname {
    p {
      font-family: $font--dbheavent;
    }
  }
  .offer-code-content {
    &__btn {
      input {
        font-family: DB Heavent;
        font-size: 16px;
      }
    }
  }
}

.review {
  .size {
    font-family: $font--dbheavent;
    font-size: 18px;
  }
  .checkout {
    &__panel-title {
      font-size: 20px;
    }
  }
  .offer-code-content {
    &__btn {
      input {
        font-family: DB Heavent;
        font-size: 16px;
      }
    }
  }
}

#pr-reviewdisplay {
  .pr-review-display {
    .pr-review {
      .pr-rd-details {
        span,
        time {
          font-family: $font--dbheavent;
          font-size: 18px;
        }
      }
      .pr-rd-description {
        .pr-rd-description-text {
          font-family: $font--dbheavent;
        }
      }
    }
    .pr-rd-review-position {
      .pr-rd-bold {
        font-family: $font--dbheavent;
      }
    }
  }
}

.foundation-finder {
  &__results-product {
    &--price,
    &--quick-shop {
      font-family: $font--dbheavent;
    }
    &--quick-shop {
      font-size: 16px !important;
    }
    &--add-to-bag {
      &.button {
        font-size: 16px;
      }
    }
  }
}

.section-mystery-of-la-mer {
  .content-block {
    &__typography {
      .heading {
        font-family: $font--dbheavent;
        p {
          font-family: $font--dbheavent;
          font-size: 18px;
        }
      }
      &.content-block-large {
        &__content-text {
          &__content {
            .heading {
              font-size: 18px;
            }
          }
          .content-block-large {
            &__content-buttons {
              .button {
                font-size: 16px;
              }
            }
          }
        }
      }
      &.content-block-collapsible {
        &__eyebrow {
          .heading {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.selectBox-dropdown-menu {
  li {
    a {
      font-family: $font--dbheavent;
      font-size: 15px;
    }
  }
}

select {
  option {
    font-family: $font--dbheavent;
    font-size: 15px;
  }
}

.section-offers {
  .content-block-tout {
    &__text-buttons {
      font-family: $font--dbheavent;
    }
  }
}

.section-stores {
  .store-locator {
    &__form {
      &--inputs {
        font-family: $font--dbheavent;
        font-size: 20px;
      }
    }
    &__form-column {
      &--submit {
        .search-submit {
          font-family: $font--dbheavent;
          font-size: 20px;
        }
      }
      &--search {
        .selectbox {
          font-family: $font--dbheavent;
          font-size: 20px;
        }
      }
    }
    &__results-container,
    &__info,
    &__tooltip-name,
    &__tooltip-address,
    &__tooltip-phone,
    &__direction {
      font-family: $font--dbheavent;
      font-size: 20px;
      line-height: 1;
    }
    &__list-item {
      &--right {
        font-size: 20px;
        line-height: 1;
      }
    }
  }
}

.spp-olapic {
  display: none;
  .content-block-collapsible {
    &__content-body {
      font-family: $font--dbheavent;
      font-size: 22px;
    }
  }
}

.content-block-split-width__product--cta,
.content-block-split-width__product--atb {
  .product-add-to-cart {
    .add-to-cart {
      font-family: $font--dbheavent;
      font-size: 18px;
      letter-spacing: 0.01em;
    }
  }
}

.content-block,
.product-grid.product-grid-v1 {
  .product-brief--show-shade-size {
    .product-brief__cta {
      .button {
        font-family: $font--dbheavent;
        font-size: 18px;
        letter-spacing: 0.01em;
      }
    }
  }
}
