.appt-book {
  .appt-book-section-content {
    padding: 5px 25px 0;
  }
  .form-container .location-select,
  .form-container .location-select option,
  .appt-book-location__signin-text,
  .book-appt-container input,
  .book-appt-container input::placeholder,
  .location-submit {
    font-size: 16px !important;
  }
  .location-link {
    .appt-book-link {
      padding-top: 8px;
      font-size: 16px !important;
    }
  }
  .appointment-info-detail h4,
  .location h4,
  .your-lessons h4 {
    letter-spacing: normal !important;
    line-height: 20px !important;
  }
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        overflow: visible;
        .registration__email-list-text {
          font-family: 'Neue Haas Unica Pro', 'DB Heavent', sans-serif;
          font-size: 12px;
          font-variant: normal;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 18px;
        }
        .registration__email-list {
          height: auto;
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
