.site-content {
  &.viewcart {
    .checkout-panel--guarantee,
    .recommended-products-panel {
      display: none;
    }
    .checkout {
      &__sidebar {
        border-top: none;
        .checkout-panel {
          &--order-summary {
            .label {
              width: 55%;
            }
            .value {
              width: 45%;
            }
          }
        }
      }
    }
    .checkout-panel {
      &--viewcart {
        .cart-items {
          &__item {
            &--price,
            &--total {
              width: 50%;
            }
          }
        }
      }
    }
    .shipping {
      border-top: none;
      margin-top: 10px;
    }
  }
}

.site-footer {
  &__bottom {
    margin-bottom: 75px;
  }
}

.signin {
  .site-header {
    padding-bottom: 10px;
    &__search {
      display: none;
    }
  }
  .responsive-container {
    border-top: solid 1px $color-light-gray;
    padding-top: 10px;
    .signin-panel {
      &__title {
        text-align: center;
        font-size: 21px;
      }
      .messages-container {
        text-align: center;
        &.error {
          color: $color-error;
        }
      }
      .new-account,
      .return-user {
        &__submit {
          width: 100%;
          font-family: $font--dbheavent;
          font-size: 16px;
        }
      }
    }
  }
}

.shipping {
  border-top: 1px solid $color-light-gray;
  margin-top: 25px;
  .shipping-info {
    .form_element {
      select {
        width: 85%;
        margin-bottom: 10px;
      }
    }
    .address-select {
      margin: 10px 0;
    }
  }
  .shipping-info,
  .billing-info,
  .delivery-info-content,
  .payment-panel,
  .giftwrap-info,
  .sub-block {
    padding: 1em 0;
  }
  .shipform_fields {
    .selectbox {
      margin: 5px 0;
    }
  }
  .gift-message {
    margin-top: 10px;
  }
  .payment-panel {
    .form-item {
      display: block;
      margin: 5px 0;
    }
    .bank-transfer-info {
      clear: both;
    }
    .bankname {
      margin-top: 10px;
    }
  }
  .payment-panel,
  .newsletter-info {
    label {
      &:before {
        margin-top: 5px;
        float: left;
      }
      span {
        width: 90%;
        display: inline-block;
      }
    }
  }
  .viewcart-buttons {
    .form-submit {
      font-family: $font--dbheavent;
      font-size: 20px;
    }
  }
  .checkout {
    &__panel {
      &-title {
        font-size: 26px;
      }
    }
  }
  .checkout-progress-bar {
    &__list-item {
      &--payment_select {
        color: $black;
        border-bottom: 1px solid $black;
        a {
          pointer-events: all;
          color: $black;
        }
      }
    }
  }
  .giftwrap-info {
    .gift-options-checkbox {
      font-size: 18px;
    }
  }
}

.checkout-progress-bar {
  &__list-item {
    font-size: 14px;
    padding: 0 0 4px 0;
    margin-right: 2px;
    width: 32%;
    line-height: 1.5;
    a {
      pointer-events: none;
      line-height: 14px;
    }
    &--review {
      margin-right: 0;
    }
  }
}

.review {
  border-top: 1px solid $color-light-gray;
  margin-top: 25px;
  .checkout-progress-bar {
    &__list-item {
      &--review {
        color: $black;
        border-bottom: 1px solid $black;
        a {
          pointer-events: all;
          color: $black;
        }
      }
      &--payment_select {
        a {
          pointer-events: all;
        }
      }
    }
  }
  &-panel {
    .gift_container--header {
      h4 {
        width: 50%;
      }
    }
    .checkout__panel-content {
      .cart-item {
        &__thumb {
          height: 180px;
          width: 30%;
        }
      }
      .cart-items__item {
        &--group-desc-remove {
          float: left;
          width: 90%;
        }
        &--price {
          margin-left: 0;
          float: none;
        }
        &--qty {
          margin-top: 10px;
        }
        &--total {
          margin-top: 10px;
          width: 68%;
        }
        &--desc {
          font-size: 15px;
        }
      }
      .cart_items {
        &__items {
          width: 68%;
          float: left;
        }
      }
    }
  }
  .checkout__sidebar {
    .links-panel {
      display: none;
    }
  }
  .checkout-panel {
    &__header {
      padding: 10px 0;
      .edit {
        padding: 0;
        border: none;
        text-decoration: underline;
        min-width: 80px;
      }
    }
  }
  .review-panel {
    .checkout-panel__header {
      border-top: 1px solid $color-light-gray;
    }
    .cart-item {
      display: flex;
    }
  }
}

.checkout {
  .viewcart-buttons-panel {
    .continue-buttons {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      margin-bottom: 10px;
      input.checkout-buttons {
        width: 90%;
        height: 45px;
        border: 1px solid $white;
        line-height: 20px;
      }
    }
  }
  .sticky-checkout-button {
    box-shadow: none;
    background: transparent;
    .btn {
      border: 1px solid $color-white;
    }
  }
  &-shipping,
  &-review,
  &-signin,
  &-samples {
    .mobile-checkout-back {
      margin-left: 0;
      display: block;
      width: 60px;
      top: 0;
      .icon {
        width: 27px;
        height: 27px;
        margin-top: 15px;
      }
    }
    .site-header {
      &__utility-left {
        a {
          display: none;
        }
      }
      &__utility-right,
      &__search,
      &__menu {
        display: none;
      }
      &__utility-item__link {
        top: 10px;
      }
      &.sticky {
        .site-header {
          &__container {
            position: relative;
          }
          &__utility-logo {
            margin: 0 auto;
          }
          &__main {
            box-shadow: none;
          }
          &__menu {
            display: none;
          }
          &__utility-left {
            &.pc-hidden {
              display: block;
            }
          }
        }
      }
    }
  }
}

.confirm {
  .checkout__panel {
    &-title {
      text-align: center;
    }
  }
}

body.toolbar-drawer {
  padding-top: 0 !important;
}
